html, body, #app {
  height: 100%;
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
}

.z0 {
  z-index: 0;
}

.z1 {
  z-index: 1;
}
